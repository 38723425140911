.pledge {
  background-color: $off-black;
  padding: bend(100px, 36px, $large-width, $small-width)
    padding-auto(108px, $container-width - 216px)
    bend(54px, 30px, $medium-width, $small-width);
  display: flex;
  justify-content: space-between;
  @media (max-width: 1080px) {
    padding-left: bend(108px, 42px, 1080px, $medium-width);
    padding-right: bend(108px, 42px, 1080px, $medium-width);
  }
  @media (max-width: $small-width) {
    align-items: center;
  }
  .alt-layout & {
    @media (max-width: $small-width) {
      flex-direction: column;
    }
  }

  .pledge-count-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.5%;
    max-width: 348px;
    text-align: center;
    .alt-layout & {
      @media (max-width: $small-width) {
        width: 100%;
        max-width: initial;
      }
    }

    h2 {
      font: 700 bend(68px, 24px, $container-width, $xsmall-width) / 100%
        $fnt-dflt;
      color: $yellow;
      letter-spacing: -0.02em;
      padding-bottom: 0.6176em;
      .alt-layout & {
        font-size: bend(68px, 28px, $container-width, $xsmall-width);
      }
    }
    .counter {
      background-color: $yellow;
      color: $off-black;
      font: 300 bend(30px, 24px, $small-width, $xsmall-width) / 100% $fnt-dflt;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      padding: 0.2em 0.15em 0 0.3em;
      .alt-layout & {
        font-size: 30px;
      }
    }
    h3 {
      font: 300 bend(10px, 12px, $small-width, $xsmall-width) / 120% $fnt-dflt;
      padding-top: 0.8em;
      color: $yellow;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      body:not(.alt-layout) & {
        letter-spacing: 0;
      }
    }
  }

  .take-pledge-section {
    width: 59.75%;
    max-width: 620px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: height 0.3s ease;
    .alt-layout & {
      @media (max-width: $small-width) {
        margin-top: 36px;
        width: 100%;
      }
    }

    .cta-text,
    .new-text {
      font: 300 bend(22px, 14px, $small-width, $xsmall-width) / 120% $fnt-dflt;
      color: $off-white;
      text-align: center;
      .alt-layout & {
        font-size: 22px;
      }
    }
    .std-btn {
      display: inline-block;
      border-color: $off-white;
      color: $off-white;
      margin-top: bend(58px, 28px, $medium-width, $xsmall-width);
      transition: background-color 0.2s linear, color 0.2s linear;
      position: relative;
      @media (min-width: $small-width-up) {
        font-size: 18px;
      }
      .alt-layout & {
        font-size: 18px;
      }
      body:not(.alt-layout) & {
        margin-top: bend(58px, 9px, $medium-width, $xsmall-width);
      }

      &::after {
        content: "Thank you";
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.2s linear;
        color: $off-white;
        padding-top: 0.3em;
      }
      &:not(.clicked) {
        @include hover {
          background-color: $off-white;
          color: $off-black;
        }
      }
      &.clicked {
        color: #fff0;
        cursor: default;
        &::after {
          opacity: 1;
        }
      }
    }

    .cta-text,
    .std-btn {
      transition: opacity 0.3s linear, color 0.2s linear,
        background-color 0.2s linear;
    }
    &.taken {
      .cta-text,
      .std-btn {
        display: none;
      }
    }
    &.transitioning {
      .cta-text,
      .std-btn {
        opacity: 0;
      }
    }

    .new-text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: none;
      opacity: 0;
      transition: opacity 0.4s linear 0.3s;
      align-items: center;
    }
    &.taken .new-text {
      display: flex;
      opacity: 1;
      position: relative;
      height: 100%;
    }
    &.transitioning .new-text {
      display: flex;
      opacity: 1;
      position: absolute;
      height: 100%;
    }
  }
}
