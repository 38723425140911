.talk {
  background-color: #e9e6da;
  text-align: center;

  &.base-section {
    padding-left: 0;
    padding-right: 0;
  }
}
.talk-container {
  max-width: 1400px;
  padding: min(88px, 6.285vw) min(42px, 8vw) min(65px, 12vw);
  margin: 0 auto;
  display: flex;
  text-align: left;
  align-items: center;

  @media (max-width: $large-width) {
    flex-direction: column;
    text-align: center;
    padding-top: 0;
  }

  .video-link {
    border: solid 1px #000;
    margin-right: min(42px, 3vw);
    width: 43%;
    img {
      max-width: 100%;
      height: auto;
      display: block;
    }

    @media (max-width: $large-width) {
      width: auto;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 10vw;
      margin-top: -20vw;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 53%;

    @media (max-width: $large-width) {
      width: auto;
      align-items: center;

      .title {
        max-width: 800px;
      }
    }
  }
}
