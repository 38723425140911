/* this is for the full site styling. Any styling that isn't specific
  to a block should be imported here along with all blocks. */
/* reset is the exception, it is order specific but not part of block styling */
/* html5doctor.com Reset v1.6.1 (http://html5doctor.com/html-5-reset-stylesheet/) - http://cssreset.com */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #444;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}
a,
:focus,
:active,
:hover {
  outline: 0;
}
/* Extra added for font weights on headers. */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
@font-face {
  font-family: "GroldSlim";
  font-weight: 300;
  font-style: normal;
  src: url("grold-slim-semilight.81f56f4c.woff2") format("woff2"), url("grold-slim-semilight.ec30d8dd.woff") format("woff");
}
@font-face {
  font-family: "GroldSlim";
  font-weight: 400;
  font-style: normal;
  src: url("grold-slim-regular.4ce2f239.woff2") format("woff2"), url("grold-slim-regular.9e1b1287.woff") format("woff");
}
@font-face {
  font-family: "GroldSlim";
  font-weight: 700;
  font-style: normal;
  src: url("grold-slim-bold.0573bf2a.woff2") format("woff2"), url("grold-slim-bold.f53e34b5.woff") format("woff");
}
@font-face {
  font-family: "GroldSlim";
  font-weight: 700;
  font-style: italic;
  src: url("grold-slim-bold-italic.617451a4.woff2") format("woff2"), url("grold-slim-bold-italic.546bc0c8.woff") format("woff");
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga=0";
  font-feature-settings: "liga=0";
  color: #1e1517;
  font: 300 min(23px,max(calc( (100vw - 400px) / 33.3333333333 + 17px ),17px))/120% "GroldSlim", Helvetica, Arial, Sans-Serif;
}
body > main {
  flex-grow: 1;
}
body.pledge-page > main {
  display: flex;
  flex-direction: column;
}
#wpadminbar {
  transform: translateY(calc(-100% + 7px));
  opacity: 0;
  transition: all 0.2s ease;
  z-index: 400;
}
#wpadminbar:hover {
  opacity: 1;
  transform: translateY(0);
}
.overflow-hidden {
  overflow: hidden;
}
.title {
  font-weight: bold;
  font-size: clamp(20px, 8vw, 48px);
  line-height: 110%;
  margin-bottom: min(30px, 5vw);
}
.video-link {
  position: relative;
}
.video-link::after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: min(50px, 6.25vw) 0 min(50px, 6.25vw) min(87px, 10.875vw);
  border-color: transparent transparent transparent #ffffffe0;
  top: calc(50% - min(50px, 6.25vw));
  left: calc(50% - min(43px, 5.4375vw));
  transition: all 0.25s ease;
}
.video-link:hover::after {
  border-color: transparent transparent transparent #ffffffff;
  transform: scale(1.1);
}
.standard-btn,
.standard-button,
.std-btn {
  padding: 0.5276em 1.5em 0.3296em;
  border: 2px solid #1e1517;
  border-radius: 100px;
  color: #1e1517;
  font: 700 14px/120% "GroldSlim", Helvetica, Arial, Sans-Serif;
  text-align: center;
  box-sizing: border-box;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  user-select: none;
  cursor: pointer;
}
@media (max-width: 400px) {
  .standard-btn,
.standard-button,
.std-btn {
    padding: 1.07em 2.1em 0.73em;
    font-size: 13px;
    border-width: 1px;
  }
}
.color-pink {
  color: #efacac;
}
/*!  scope with: inc/header.php */
header {
  background-color: #f1f2f2;
  padding: 33px max(42px,calc(50vw - 540px));
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  transition: padding-top 0.3s ease, padding-bottom 0.3s ease;
}
@media (max-width: 600px) {
  header {
    padding-left: min(42px,max(calc( (100vw - 400px) / 8.3333333333 + 18px ),18px));
    padding-right: min(42px,max(calc( (100vw - 400px) / 8.3333333333 + 18px ),18px));
  }
}
@media (max-width: 400px) {
  header {
    padding-top: 28px;
    padding-bottom: 28px;
  }
}
header.shrink {
  padding-top: 16px;
  padding-bottom: 16px;
}
#logo {
  order: 2;
  flex-shrink: 0;
}
#logo h1 {
  opacity: 0.001;
  font-size: 1px;
  position: absolute;
  top: 0;
  left: 0;
}
#logo::before {
  content: "";
  background: url("logo.a12acef4.svg") no-repeat center/contain;
  width: min(222px,max(calc( (100vw - 400px) / 1.0416666667 + 150px ),150px));
  padding-top: 35.5%;
  margin-bottom: -25px;
  display: block;
  transition: transform 0.3s ease;
}
@media (max-width: 400px) {
  #logo::before {
    margin-bottom: -17px;
  }
}
header.shrink #logo::before {
  transform: scale(0.5945) translateY(-25%);
}
@media (max-width: 400px) {
  header.shrink #logo::before {
    transform: scale(0.75) translateY(-15%);
  }
}
#header-nav {
  order: 1;
  flex-shrink: 0;
  width: 34px;
  height: 29px;
  background: url("menu-closed.17721904.svg") no-repeat center/contain;
  margin-right: min(114px,max(calc( (100vw - 750px) / 6.9444444444 + 78px ),78px));
  cursor: pointer;
  visibility: hidden;
}
@media (max-width: 600px) {
  #header-nav {
    margin-right: min(78px,max(calc( (100vw - 522px) / 1 + 0px ),0px));
  }
}
#header-nav .menu {
  display: none;
}
@media (max-width: 600px) {
  #header-nav {
    display: none;
  }
}
#get-help {
  order: 3;
  transition: color 0.3s linear, background-color 0.3s linear;
  margin-right: min(36px,max(calc( (100vw - 750px) / 6.9444444444 + 0px ),0px));
}
@media (max-width: 400px) {
  body:not(.alt-layout) #get-help {
    margin-bottom: -36px;
    transition: color 0.3s linear, background-color 0.3s linear, margin-bottom 0.3s ease;
  }
  body:not(.alt-layout) header.shrink #get-help {
    margin-bottom: -6px;
  }
}
html:not(.is-touch) #get-help:hover {
  background-color: #1e1517;
  color: #f1f2f2;
}
html.is-touch #get-help:active {
  background-color: #1e1517;
  color: #f1f2f2;
}
/*! end scope */
main {
  padding-top: min(120px,max(calc( (100vw - 400px) / 4.1428571429 + 99px ),99px));
}
@media (max-width: 400px) {
  main {
    padding-top: 89px;
  }
}
footer {
  background-color: #1e1517;
  padding: 32px max(42px,calc(50vw - 477px)) min(30px,max(calc( (100vw - 400px) / 11.1111111111 + 12px ),12px));
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
}
@media (max-width: 600px) {
  footer {
    flex-direction: column;
    gap: 48px;
  }
}
footer .logo {
  width: 130px;
  height: 46px;
  background: url("logo-white.fab86302.svg") no-repeat center/contain;
  order: 3;
}
@media (max-width: 600px) {
  footer .logo {
    width: 213px;
    height: 76px;
    order: 1;
  }
}
footer .links-wrap {
  order: 2;
  display: flex;
}
footer .social-links {
  display: flex;
  gap: 17px;
}
footer .social-links > a {
  width: 32px;
  height: 32px;
  background-color: #f1f2f2;
  transition: background-color 0.2s linear;
  display: block;
  position: relative;
  border-radius: 24%;
}
html:not(.is-touch) footer .social-links > a:hover {
  background-color: #d0c200;
}
html.is-touch footer .social-links > a:active {
  background-color: #d0c200;
}
footer .social-links > a::before {
  content: "";
  position: absolute;
  top: 16%;
  left: 16%;
  right: 16%;
  bottom: 16%;
  background: no-repeat center/contain;
}
footer .social-links .facebook::before {
  background-image: url("logo-facebook.124fb3cc.svg");
}
footer .social-links .instagram::before {
  background-image: url("logo-instagram.08ca4367.svg");
}
footer .social-links .twitter::before {
  background-image: url("logo-twitter.c1e78938.svg");
}
footer .alt-links {
  padding-left: 26px;
}
footer .alt-links > a {
  display: block;
  color: #f1f2f2;
  font: 700 13px/100% "GroldSlim", Helvetica, Arial, Sans-Serif;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  transition: color 0.2s linear;
  margin-bottom: 0.4em;
}
html:not(.is-touch) footer .alt-links > a:hover {
  color: #d0c200;
}
html.is-touch footer .alt-links > a:active {
  color: #d0c200;
}
footer .copyright {
  order: 4;
  font: 700 11px/100% "GroldSlim", Helvetica, Arial, Sans-Serif;
  color: #f1f2f2;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-left: min(152px,max(calc( (100vw - 648px) / 1 + 0px ),0px));
}
/* block only styling */
/*! scope with: inc/hero.php */
.hero {
  background-color: #000;
  padding: 60px 12px 90px 12px;
}
@media (max-width: 1000px) {
  .hero {
    padding-bottom: 25vw;
  }
}
.hero .rotating-images {
  height: min(400px, 36.363vw);
  position: relative;
  margin-bottom: min(60px, 6vw);
}
.hero .rotating-images img {
  position: absolute;
  top: 0;
  left: 50%;
  width: auto;
  height: 100%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity linear 0.4s;
}
.hero .rotating-images img.current {
  opacity: 1;
}
.hero h2 {
  font: 700 clamp(24px, 8.6vw, 80px)/100% "GroldSlim", Helvetica, Arial, Sans-Serif;
  letter-spacing: -0.02em;
  color: #d0c200;
  text-align: center;
  position: relative;
  max-width: 820px;
  margin: 0 auto;
  text-transform: uppercase;
}
.pledge-page .hero {
  padding: min(55px,max(calc( (100vw - 600px) / 20 + 35px ),35px)) max(45px,calc(50vw - 700px));
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  background-color: transparent;
  min-height: 280px;
  align-items: flex-start;
  padding-top: min(56px,max(calc( (100vw - 400px) / 17.5 + 36px ),36px));
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 25vw;
}
@media (max-width: 1000px) {
  .pledge-page .hero {
    min-height: min(420px,max(calc( (100vw - 400px) / 2.6315789474 + 192px ),192px));
  }
}
@media (max-width: 1000px) {
  .pledge-page .hero {
    min-height: min(280px,max(calc( (100vw - 400px) / 3.9772727273 + 192px ),192px));
  }
}
.pledge-page .hero > img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
}
.pledge-page .hero h2 {
  font: 700 min(80px,max(calc( (100vw - 400px) / 27.1428571429 + 24px ),24px))/100% "GroldSlim", Helvetica, Arial, Sans-Serif;
  letter-spacing: -0.02em;
  color: #d0c200;
  text-align: center;
  position: relative;
  max-width: 1000px;
  text-transform: none;
}
.alt-layout .pledge-page .hero h2 {
  font-size: min(80px,max(calc( (100vw - 400px) / 33.0434782609 + 34px ),34px));
}
/*! end scope */
/*! scope with: inc/plain-text.php */
.plain-text {
  background-color: #1e1517;
  padding: min(60px,max(calc( (100vw - 600px) / 25 + 44px ),44px)) max(42px,calc(50vw - 432px)) 28px;
  color: #f1f2f2;
  font: 300 min(20px,max(calc( (100vw - 400px) / 33.3333333333 + 14px ),14px))/130% "GroldSlim", Helvetica, Arial, Sans-Serif;
  text-align: center;
  position: relative;
}
.alt-layout .plain-text {
  font-size: min(20px,max(calc( (100vw - 400px) / 66.6666666667 + 17px ),17px));
  padding-top: 60px;
}
.plain-text::before {
  content: "";
  height: 6px;
  width: 42px;
  background-color: #d0c200;
  position: absolute;
  top: min(34px,max(calc( (100vw - 600px) / 80 + 29px ),29px));
  left: calc(50% - 21px);
}
.plain-text p {
  padding-bottom: 0.7em;
}
/*! end scope */
/*! scope with: inc/popup-carousel.php */
.popup-list {
  background-color: #efacac;
  padding: min(100px, 12.5vw) 12px min(120px, 15vw);
}
@media (max-width: 600px) {
  .popup-list.base-section {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.popup-list > .title {
  color: #1e1517;
  letter-spacing: 0.02em;
  margin-bottom: min(60px, 7.5vw);
  text-align: center;
}
.popup-list .list-items {
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}
.popup-list .list-item {
  padding: 1.8518% 2.2222% 0;
  margin: 8px 7px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: calc(20% - 14px);
  min-width: 163px;
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: #fff;
}
@media (max-width: 1200px) {
  .popup-list .list-item {
    width: calc(25% - 14px);
  }
}
@media (max-width: 1000px) {
  .popup-list .list-item {
    width: calc(33.333% - 10px);
    margin: 5px;
  }
}
@media (max-width: 750px) {
  .popup-list .list-item {
    width: calc(50% - 8px);
    margin: 4px;
  }
}
@media (max-width: 360px) {
  .popup-list .list-item {
    width: 100%;
  }
}
.popup-list .list-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 10px solid #d8df52;
  opacity: 0;
  transform: scale(1.073);
  transition: opacity 0.3s linear, transform 0.4s ease;
}
html:not(.is-touch) .popup-list .list-item:hover::after {
  opacity: 1;
  transform: scale(1);
}
html.is-touch .popup-list .list-item:active::after {
  opacity: 1;
  transform: scale(1);
}
.popup-list .list-item[for=open-up]::before {
  background-image: url("open-up.dbce5dd9.svg");
  background-size: 52.1807% auto;
}
.popup-list .list-item[for=walk-shoes]::before {
  background-image: url("walk-shoes.cd576fe7.svg");
  background-size: 54.1807% auto;
  background-position: center 68%;
}
.popup-list .list-item[for=be-clear]::before {
  background-image: url("be-clear.c68bc81f.svg");
  background-size: 46% auto;
}
.popup-list .list-item[for=stay-calm]::before {
  background-image: url("stay-calm.27b34e52.svg");
  background-size: 60% auto;
  background-position: center 68%;
}
.popup-list .list-item[for=use-positivity]::before {
  background-image: url("use-positivity.de404439.svg");
  background-size: 48.1927% auto;
}
.popup-list .list-item[for=talk-no-lecture]::before {
  background-image: url("talk-no-lecture.8ae36bf1.svg");
  background-size: 50% auto;
}
.popup-list .list-item[for=mind-language]::before {
  background-image: url("mind-language.2c19e538.svg");
  background-size: 48.2138% auto;
}
.popup-list .list-item[for=active-listening]::before {
  background-image: url("active-listening.66352d71.svg");
  background-size: 53.0351% auto;
}
.popup-list .list-item[for=stick-science]::before {
  background-image: url("stick-science.36a9449f.svg");
  background-size: 48.2138% auto;
}
.popup-list .list-item::before {
  display: block;
  content: "";
  padding-top: 69.94%;
  background-repeat: no-repeat;
  background-position: bottom;
}
.popup-list .list-item .title {
  font: clamp(11px, 2vw, 20px)/100% "GroldSlim", Helvetica, Arial, Sans-Serif;
  color: #1e1517;
  text-align: center;
  padding-top: 16.8674%;
  padding-bottom: 15%;
  margin-bottom: 0;
}
@media (max-width: 750px) {
  .popup-list .list-item .title {
    font-size: clamp(11px, 3.333vw, 20px);
  }
}
@media (max-width: 360px) {
  .popup-list .list-item .title {
    font-size: 20px;
  }
}
/*! end scope */
.talk {
  background-color: #e9e6da;
  text-align: center;
}
.talk.base-section {
  padding-left: 0;
  padding-right: 0;
}
.talk-container {
  max-width: 1400px;
  padding: min(88px, 6.285vw) min(42px, 8vw) min(65px, 12vw);
  margin: 0 auto;
  display: flex;
  text-align: left;
  align-items: center;
}
@media (max-width: 1000px) {
  .talk-container {
    flex-direction: column;
    text-align: center;
    padding-top: 0;
  }
}
.talk-container .video-link {
  border: solid 1px #000;
  margin-right: min(42px, 3vw);
  width: 43%;
}
.talk-container .video-link img {
  max-width: 100%;
  height: auto;
  display: block;
}
@media (max-width: 1000px) {
  .talk-container .video-link {
    width: auto;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 10vw;
    margin-top: -20vw;
  }
}
.talk-container .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 53%;
}
@media (max-width: 1000px) {
  .talk-container .content {
    width: auto;
    align-items: center;
  }
  .talk-container .content .title {
    max-width: 800px;
  }
}
/*! scope with: inc/popup-carousel.php */
.popup-carousel {
  background-color: #f1f2f2;
}
@media (max-width: 600px) {
  .popup-carousel.popup-carousel {
    padding-left: 0;
    padding-right: 0;
  }
}
.popup-carousel > .title {
  color: #1e1517;
  font: 700 min(68px,max(calc( (100vw - 400px) / 22.7272727273 + 24px ),24px))/100% "GroldSlim", Helvetica, Arial, Sans-Serif;
  letter-spacing: 0.02em;
  padding: min(66px,max(calc( (100vw - 600px) / 11.1111111111 + 30px ),30px)) max(42px,calc(50vw - 360px)) 30px;
  text-align: center;
}
.alt-layout .popup-carousel > .title {
  font-size: min(68px,max(calc( (100vw - 400px) / 25 + 28px ),28px));
}
.popup-carousel .carousel {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}
.popup-carousel .carousel .prev-btn,
.popup-carousel .carousel .next-btn {
  position: absolute;
  width: 40px;
  height: 40px;
  background: url("arrow.84aea0c7.svg") no-repeat center/15px auto;
  top: calc(50% - 15px);
  cursor: pointer;
  z-index: 3;
}
.popup-carousel .carousel .prev-btn {
  left: min(5px,max(calc( (1500px - 100vw) / 2.2222222222 + -40px ),-40px));
}
.popup-carousel .carousel .next-btn {
  transform: scaleX(-1);
  right: min(5px,max(calc( (1500px - 100vw) / 2.2222222222 + -40px ),-40px));
}
@media (max-width: 1400px) {
  .popup-carousel .carousel .prev-btn,
.popup-carousel .carousel .next-btn {
    width: 28px;
    height: 28px;
  }
  .alt-layout .popup-carousel .carousel .prev-btn,
.alt-layout .popup-carousel .carousel .next-btn {
    width: 32px;
    height: 32px;
    background-size: auto 63%;
    background-color: #fff;
    border-radius: 100px;
  }
}
.popup-carousel .carousel-items {
  display: flex;
  overflow: auto;
}
.popup-carousel .carousel-item {
  padding: 1.8518% 2.2222% 2.9629%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 20%;
  min-width: 163px;
  box-sizing: border-box;
  flex-shrink: 0;
}
.popup-carousel .carousel-item:nth-child(odd) {
  background-color: #efefef;
}
.popup-carousel .carousel-item:nth-child(even) {
  background-color: #e2e2e2;
}
.alt-layout .popup-carousel .carousel-item {
  min-width: 198px;
}
.popup-carousel .carousel-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 10px solid #000;
  opacity: 0;
  transform: scale(1.073);
  transition: opacity 0.3s linear, transform 0.4s ease;
}
html:not(.is-touch) .popup-carousel .carousel-item:hover::after {
  opacity: 0.15;
  transform: scale(1);
}
html.is-touch .popup-carousel .carousel-item:active::after {
  opacity: 0.15;
  transform: scale(1);
}
.popup-carousel .carousel-item[for=mem-loss]::before {
  background-image: url("memory-loss.6164ac5c.svg");
  background-size: 45.1807% auto;
}
.popup-carousel .carousel-item[for=use-disorder]::before {
  background-image: url("disorder-chain.46be1244.svg");
  background-size: 100% auto;
}
.popup-carousel .carousel-item[for=anxiety]::before {
  background-image: url("anxiety-bolt.1e01546d.svg");
  background-size: 34.2108% auto;
}
.popup-carousel .carousel-item[for=distraction]::before {
  background-image: url("squirrel.9f986928.svg");
  background-size: 48.1927% auto;
}
.popup-carousel .carousel-item[for=iq-decline]::before {
  background-image: url("iq-bulb.55181b61.svg");
  background-size: 29.1626% auto;
}
.popup-carousel .carousel-item[for=psychosis]::before {
  background-image: url("spiral.638f912d.svg");
  background-size: 48.2138% auto;
}
.popup-carousel .carousel-item[for=suicidal]::before {
  background-image: url("suicidal.4cd55d72.svg");
  background-size: 53.0351% auto;
}
.popup-carousel .carousel-item[for=poor-judgement]::before {
  background-image: url("poor-judge.12ef461e.svg");
  background-size: 48.2138% auto;
}
.popup-carousel .carousel-item[for=dropping-out]::before {
  background-image: url("bad-grade.9dc7cbb5.svg");
  background-size: 48.2138% auto;
}
.popup-carousel .carousel-item[for=substance-abuse]::before {
  background-image: url("substance-abuse.f86ea93f.svg");
  background-size: 49.8209% auto;
}
.popup-carousel .carousel-item::before {
  display: block;
  content: "";
  padding-top: 69.94%;
  background-repeat: no-repeat;
  background-position: bottom;
}
.popup-carousel .carousel-item .title {
  font: min(14px,max(calc( (100vw - 400px) / 66.6666666667 + 11px ),11px))/100% "GroldSlim", Helvetica, Arial, Sans-Serif;
  color: #1e1517;
  text-align: center;
  text-transform: uppercase;
  padding-top: 16.8674%;
  margin-bottom: 0;
}
.alt-layout .popup-carousel .carousel-item .title {
  font-size: 14px;
}
/*! end scope */
.suggestions {
  padding: min(140px,max(calc( (100vw - 750px) / 10.8333333333 + 80px ),80px)) max(42px,calc(50vw - 550px)) min(108px,max(calc( (100vw - 600px) / 16.6666666667 + 60px ),60px));
  position: relative;
  background-color: #efacac;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 600px) {
  .alt-layout .suggestions {
    padding-bottom: 200px;
  }
}
.suggestions .text-wrap {
  width: 60.8974%;
}
@media (max-width: 600px) {
  .suggestions .text-wrap {
    width: 69%;
  }
  .alt-layout .suggestions .text-wrap {
    width: initial;
  }
}
.suggestions .text-wrap h2 {
  font: 700 min(68px,max(calc( (100vw - 400px) / 22.7272727273 + 24px ),24px))/120% "GroldSlim", Helvetica, Arial, Sans-Serif;
  color: #f1f2f2;
  letter-spacing: 0.02em;
  padding-bottom: 0.382352em;
}
.alt-layout .suggestions .text-wrap h2 {
  font-size: min(68px,max(calc( (100vw - 400px) / 25 + 28px ),28px));
}
.suggestions .text-wrap h3,
.suggestions .text-wrap p {
  font: min(18px,max(calc( (100vw - 400px) / 50 + 14px ),14px))/144.444% "GroldSlim", Helvetica, Arial, Sans-Serif;
}
.alt-layout .suggestions .text-wrap h3,
.alt-layout .suggestions .text-wrap p {
  font-size: min(18px,max(calc( (100vw - 400px) / 100 + 16px ),16px));
}
.suggestions .text-wrap h3 {
  font-weight: 700;
  color: #1e1517;
  font-style: italic;
}
.suggestions .text-wrap p {
  color: #1e1517;
  padding-bottom: 1em;
}
.suggestions .text-wrap .std-btn {
  background-color: #fff4;
  transition: color 0.3s linear, background-color 0.3s linear;
  display: inline-block;
  margin-top: min(52px,max(calc( (100vw - 400px) / 7.9545454545 + 8px ),8px));
}
html:not(.is-touch) .suggestions .text-wrap .std-btn:hover {
  background-color: #1e1517;
  color: #f1f2f2;
}
html.is-touch .suggestions .text-wrap .std-btn:active {
  background-color: #1e1517;
  color: #f1f2f2;
}
.suggestions img {
  position: absolute;
  left: max(0px, 50vw - 700px);
  top: 50%;
  transform: translate(-50%, -42%);
  height: auto;
  width: auto;
  max-height: 90%;
}
@media (max-width: 1080px) {
  .suggestions img {
    width: min(745px,max(calc( (100vw - 300px) / 1.5145631068 + 230px ),230px));
  }
}
@media (max-width: 600px) {
  .suggestions img {
    transform: translate(-61%, -42%);
  }
  .alt-layout .suggestions img {
    width: 300px;
    height: auto;
    left: 50%;
    top: 90%;
    transform: translate(-50%, -38%);
  }
}
.pledge {
  background-color: #1e1517;
  padding: min(100px,max(calc( (100vw - 600px) / 6.25 + 36px ),36px)) max(108px,calc(50vw - 592px)) min(54px,max(calc( (100vw - 600px) / 6.25 + 30px ),30px));
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1080px) {
  .pledge {
    padding-left: min(108px,max(calc( (100vw - 750px) / 5 + 42px ),42px));
    padding-right: min(108px,max(calc( (100vw - 750px) / 5 + 42px ),42px));
  }
}
@media (max-width: 600px) {
  .pledge {
    align-items: center;
  }
}
@media (max-width: 600px) {
  .alt-layout .pledge {
    flex-direction: column;
  }
}
.pledge .pledge-count-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.5%;
  max-width: 348px;
  text-align: center;
}
@media (max-width: 600px) {
  .alt-layout .pledge .pledge-count-section {
    width: 100%;
    max-width: initial;
  }
}
.pledge .pledge-count-section h2 {
  font: 700 min(68px,max(calc( (100vw - 400px) / 22.7272727273 + 24px ),24px))/100% "GroldSlim", Helvetica, Arial, Sans-Serif;
  color: #d0c200;
  letter-spacing: -0.02em;
  padding-bottom: 0.6176em;
}
.alt-layout .pledge .pledge-count-section h2 {
  font-size: min(68px,max(calc( (100vw - 400px) / 25 + 28px ),28px));
}
.pledge .pledge-count-section .counter {
  background-color: #d0c200;
  color: #1e1517;
  font: 300 min(30px,max(calc( (100vw - 400px) / 33.3333333333 + 24px ),24px))/100% "GroldSlim", Helvetica, Arial, Sans-Serif;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding: 0.2em 0.15em 0 0.3em;
}
.alt-layout .pledge .pledge-count-section .counter {
  font-size: 30px;
}
.pledge .pledge-count-section h3 {
  font: 300 min(12px,max(calc( (600px - 100vw) / 100 + 10px ),10px))/120% "GroldSlim", Helvetica, Arial, Sans-Serif;
  padding-top: 0.8em;
  color: #d0c200;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
body:not(.alt-layout) .pledge .pledge-count-section h3 {
  letter-spacing: 0;
}
.pledge .take-pledge-section {
  width: 59.75%;
  max-width: 620px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: height 0.3s ease;
}
@media (max-width: 600px) {
  .alt-layout .pledge .take-pledge-section {
    margin-top: 36px;
    width: 100%;
  }
}
.pledge .take-pledge-section .cta-text,
.pledge .take-pledge-section .new-text {
  font: 300 min(22px,max(calc( (100vw - 400px) / 25 + 14px ),14px))/120% "GroldSlim", Helvetica, Arial, Sans-Serif;
  color: #f1f2f2;
  text-align: center;
}
.alt-layout .pledge .take-pledge-section .cta-text,
.alt-layout .pledge .take-pledge-section .new-text {
  font-size: 22px;
}
.pledge .take-pledge-section .std-btn {
  display: inline-block;
  border-color: #f1f2f2;
  color: #f1f2f2;
  margin-top: min(58px,max(calc( (100vw - 400px) / 11.6666666667 + 28px ),28px));
  transition: background-color 0.2s linear, color 0.2s linear;
  position: relative;
}
@media (min-width: 601px) {
  .pledge .take-pledge-section .std-btn {
    font-size: 18px;
  }
}
.alt-layout .pledge .take-pledge-section .std-btn {
  font-size: 18px;
}
body:not(.alt-layout) .pledge .take-pledge-section .std-btn {
  margin-top: min(58px,max(calc( (100vw - 400px) / 7.1428571429 + 9px ),9px));
}
.pledge .take-pledge-section .std-btn::after {
  content: "Thank you";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s linear;
  color: #f1f2f2;
  padding-top: 0.3em;
}
html:not(.is-touch) .pledge .take-pledge-section .std-btn:not(.clicked):hover {
  background-color: #f1f2f2;
  color: #1e1517;
}
html.is-touch .pledge .take-pledge-section .std-btn:not(.clicked):active {
  background-color: #f1f2f2;
  color: #1e1517;
}
.pledge .take-pledge-section .std-btn.clicked {
  color: #fff0;
  cursor: default;
}
.pledge .take-pledge-section .std-btn.clicked::after {
  opacity: 1;
}
.pledge .take-pledge-section .cta-text,
.pledge .take-pledge-section .std-btn {
  transition: opacity 0.3s linear, color 0.2s linear, background-color 0.2s linear;
}
.pledge .take-pledge-section.taken .cta-text,
.pledge .take-pledge-section.taken .std-btn {
  display: none;
}
.pledge .take-pledge-section.transitioning .cta-text,
.pledge .take-pledge-section.transitioning .std-btn {
  opacity: 0;
}
.pledge .take-pledge-section .new-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  opacity: 0;
  transition: opacity 0.4s linear 0.3s;
  align-items: center;
}
.pledge .take-pledge-section.taken .new-text {
  display: flex;
  opacity: 1;
  position: relative;
  height: 100%;
}
.pledge .take-pledge-section.transitioning .new-text {
  display: flex;
  opacity: 1;
  position: absolute;
  height: 100%;
}
.instagram-feed {
  background-color: #e2e2e2;
  padding: min(46px,max(calc( (100vw - 400px) / 6.6666666667 + 16px ),16px)) max(120px,calc(50vw - 577px)) min(56px,max(calc( (100vw - 400px) / 14.5833333333 + 32px ),32px));
}
@media (max-width: 1080px) {
  .instagram-feed {
    padding-left: min(120px,max(calc( (100vw - 600px) / 6.1538461538 + 42px ),42px));
    padding-right: min(120px,max(calc( (100vw - 600px) / 6.1538461538 + 42px ),42px));
  }
}
.instagram-feed h2 {
  font: 700 24px/120% "GroldSlim", Helvetica, Arial, Sans-Serif;
  color: #1e1517;
  padding-bottom: min(21px,max(calc( (100vw - 400px) / 40 + 16px ),16px));
  text-align: center;
  text-transform: uppercase;
}
.instagram-feed h2 a {
  color: inherit;
}
@media (max-width: 600px) {
  .instagram-feed h2 {
    text-transform: none;
  }
}
.partners {
  padding: min(52px,max(calc( (100vw - 400px) / 7.1428571429 + 24px ),24px)) 0 min(60px,max(calc( (100vw - 400px) / 7.1428571429 + 32px ),32px));
  max-width: 1160px;
  margin: 0 auto;
}
.partners h2 {
  font: 700 24px/120% "GroldSlim", Helvetica, Arial, Sans-Serif;
  color: #1e1517;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 600px) {
  .partners h2 {
    text-transform: none;
  }
}
.partners .partners-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: min(38px,max(calc( (100vw - 400px) / 10 + 18px ),18px));
}
.partners .partners-wrap img {
  height: min(150px, 12.931vw);
  width: auto;
  margin-bottom: 0.8%;
}
.pledge-2 {
  background-color: #efacac;
  position: relative;
  padding: 0 42px 24px;
  flex-grow: 1;
}
.pledge-2 .pledge-cover {
  background-color: #231f20;
  padding: min(70px,max(calc( (100vw - 400px) / 11.6666666667 + 40px ),40px)) 24px;
  position: relative;
  z-index: 2;
  top: min(-68px,max(calc( (600px - 100vw) / 5 + -108px ),-108px));
  max-width: 894px;
  margin: 0 auto;
}
.pledge-2 .text {
  font: min(20px,max(calc( (100vw - 400px) / 50 + 16px ),16px))/140% "GroldSlim", Helvetica, Arial, Sans-Serif;
  max-width: 690px;
  margin: 0 auto;
  color: #f1f2f2;
  text-align: center;
}
.pledge-2 .pledge-count-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pledge-2 .pledge-count-section h2 {
  font: 700 min(60px,max(calc( (100vw - 400px) / 27.7777777778 + 24px ),24px))/100% "GroldSlim", Helvetica, Arial, Sans-Serif;
  color: #d0c200;
  letter-spacing: -0.05em;
  padding-top: 0.95em;
}
.alt-layout .pledge-2 .pledge-count-section h2 {
  font-size: min(60px,max(calc( (100vw - 400px) / 31.25 + 28px ),28px));
}
.pledge-2 .pledge-count-section .counter {
  background-color: #d0c200;
  color: #1e1517;
  font: 300 min(30px,max(calc( (100vw - 400px) / 33.3333333333 + 24px ),24px))/100% "GroldSlim", Helvetica, Arial, Sans-Serif;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  padding: 0.2em 0.15em 0 0.3em;
}
.alt-layout .pledge-2 .pledge-count-section .counter {
  font-size: 30px;
}
.pledge-2 .pledge-count-section h3 {
  font: 300 min(12px,max(calc( (600px - 100vw) / 100 + 10px ),10px))/120% "GroldSlim", Helvetica, Arial, Sans-Serif;
  padding-top: 0.8em;
  color: #d0c200;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
body:not(.alt-layout) .pledge-2 .pledge-count-section h3 {
  letter-spacing: 0;
}
.pledge-2 .take-pledge-section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: height 0.3s ease;
  padding-top: 40px;
}
.pledge-2 .take-pledge-section .cta-text,
.pledge-2 .take-pledge-section .new-text {
  max-width: 594px;
  font: 300 min(22px,max(calc( (100vw - 400px) / 25 + 14px ),14px))/120% "GroldSlim", Helvetica, Arial, Sans-Serif;
  color: #f1f2f2;
  text-align: center;
}
.alt-layout .pledge-2 .take-pledge-section .cta-text,
.alt-layout .pledge-2 .take-pledge-section .new-text {
  font-size: 22px;
}
.pledge-2 .take-pledge-section .std-btn {
  display: inline-block;
  border-color: #f1f2f2;
  color: #f1f2f2;
  margin-top: 22px;
  transition: background-color 0.2s linear, color 0.2s linear;
  position: relative;
}
@media (min-width: 601px) {
  .pledge-2 .take-pledge-section .std-btn {
    font-size: 18px;
  }
}
.alt-layout .pledge-2 .take-pledge-section .std-btn {
  font-size: 18px;
}
body:not(.alt-layout) .pledge-2 .take-pledge-section .std-btn {
  margin-top: min(58px,max(calc( (100vw - 400px) / 7.1428571429 + 9px ),9px));
}
.pledge-2 .take-pledge-section .std-btn::after {
  content: "Thank you";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.2s linear;
  color: #f1f2f2;
  padding-top: 0.3em;
}
html:not(.is-touch) .pledge-2 .take-pledge-section .std-btn:not(.clicked):hover {
  background-color: #f1f2f2;
  color: #1e1517;
}
html.is-touch .pledge-2 .take-pledge-section .std-btn:not(.clicked):active {
  background-color: #f1f2f2;
  color: #1e1517;
}
.pledge-2 .take-pledge-section .std-btn.clicked {
  color: #fff0;
  cursor: default;
}
.pledge-2 .take-pledge-section .std-btn.clicked::after {
  opacity: 1;
}
.pledge-2 .take-pledge-section .cta-text,
.pledge-2 .take-pledge-section .std-btn {
  transition: opacity 0.3s linear, color 0.2s linear, background-color 0.2s linear;
}
.pledge-2 .take-pledge-section.taken .cta-text,
.pledge-2 .take-pledge-section.taken .std-btn {
  display: none;
}
.pledge-2 .take-pledge-section.transitioning .cta-text,
.pledge-2 .take-pledge-section.transitioning .std-btn {
  opacity: 0;
}
.pledge-2 .take-pledge-section .new-text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  opacity: 0;
  transition: opacity 0.4s linear 0.3s;
  align-items: center;
}
.pledge-2 .take-pledge-section.taken .new-text {
  display: flex;
  opacity: 1;
  position: relative;
  height: 100%;
}
.pledge-2 .take-pledge-section.transitioning .new-text {
  display: flex;
  opacity: 1;
  position: absolute;
  height: 100%;
}
.pledge-2 .brain-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  max-height: 1152px;
}
.pledge-2 .brain-wrapper img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 68%);
}
@media (min-height: 1236px) {
  .pledge-2 .brain-wrapper img {
    transform: translate(-50%, min(408px, max((100vh - 1668px) / -1.0588235294 + 0px, 0px)));
  }
}
@media (max-width: 600px) {
  .base-section {
    padding-left: min(42px,max(calc( (100vw - 400px) / 8.3333333333 + 18px ),18px));
    padding-right: min(42px,max(calc( (100vw - 400px) / 8.3333333333 + 18px ),18px));
  }
}
#popups {
  width: 0;
  height: 0;
}
#popups input[name=popup] {
  display: none;
}
#popup-container {
  width: 0;
  height: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s linear, height 0s linear 0.2s, width 0s linear 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 201;
  overflow: hidden;
}
#popup-close:not(:checked) ~ #popup-container {
  width: 100vw;
  height: 100vh;
  opacity: 1;
  transition: opacity 0.2s linear, height 0s linear, width 0s linear;
}
#popup-container .modal-wrap {
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
}
#popup-shadow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0005;
}
#popup-modal {
  position: relative;
  padding: 64px min(50px,max(calc( (100vw - 400px) / 5.8823529412 + 16px ),16px)) 30px;
  width: calc(100% - 24px);
  max-width: 580px;
  border: 1px solid #1e1517;
  background-color: #f1f2f2;
  box-sizing: border-box;
  display: flex;
  margin: 12px 0;
}
.video-checkbox:checked ~ #popup-container #popup-modal {
  max-width: 1000px;
  padding: 64px 0 0 0;
}
#popup-modal .exit {
  position: absolute;
  top: 25px;
  right: min(25px,max(calc( (100vw - 400px) / 28.5714285714 + 18px ),18px));
  width: 30px;
  height: 30px;
  cursor: pointer;
}
#popup-modal .exit::before, #popup-modal .exit::after {
  content: "";
  position: absolute;
  width: 141.42%;
  height: 1px;
  top: 50%;
  left: 50%;
  background-color: #1e1517;
}
#popup-modal .exit::before {
  transform: translate(-50%, -50%) rotate(45deg);
}
#popup-modal .exit::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
#popup-modal .modal-content {
  width: 0;
  overflow: hidden;
  transition: width 0s linear 0.2s;
}
#popup-modal .content-wrap {
  width: 10000px;
  transition: width 0s linear 0.2s;
}
/* SPECIFIC MODAL STYLES */
#iq-decline:checked ~ #popup-container #iq-decline-content {
  width: 100%;
  transition: width 0s linear;
}
#iq-decline:checked ~ #popup-container #iq-decline-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#iq-decline-content .content-wrap::before {
  max-width: 72px;
  background: url("iq-bulb.55181b61.svg") no-repeat top center/91.6666% auto;
}
#distraction:checked ~ #popup-container #distraction-content {
  width: 100%;
  transition: width 0s linear;
}
#distraction:checked ~ #popup-container #distraction-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#distraction-content .content-wrap::before {
  max-width: 114px;
  background: url("squirrel.9f986928.svg") no-repeat top center/91.6666% auto;
}
#anxiety:checked ~ #popup-container #anxiety-content {
  width: 100%;
  transition: width 0s linear;
}
#anxiety:checked ~ #popup-container #anxiety-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#anxiety-content .content-wrap::before {
  max-width: 78px;
  background: url("anxiety-bolt.1e01546d.svg") no-repeat top center/91.6666% auto;
}
#use-disorder:checked ~ #popup-container #use-disorder-content {
  width: 100%;
  transition: width 0s linear;
}
#use-disorder:checked ~ #popup-container #use-disorder-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#use-disorder-content .content-wrap::before {
  max-width: 168px;
  background: url("disorder-chain.46be1244.svg") no-repeat top center/91.6666% auto;
}
#mem-loss:checked ~ #popup-container #mem-loss-content {
  width: 100%;
  transition: width 0s linear;
}
#mem-loss:checked ~ #popup-container #mem-loss-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#mem-loss-content .content-wrap::before {
  max-width: 114px;
  background: url("memory-loss.6164ac5c.svg") no-repeat top center/91.6666% auto;
}
#psychosis:checked ~ #popup-container #psychosis-content {
  width: 100%;
  transition: width 0s linear;
}
#psychosis:checked ~ #popup-container #psychosis-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#psychosis-content .content-wrap::before {
  max-width: 72px;
  background: url("spiral.638f912d.svg") no-repeat top center/91.6666% auto;
}
#suicidal:checked ~ #popup-container #suicidal-content {
  width: 100%;
  transition: width 0s linear;
}
#suicidal:checked ~ #popup-container #suicidal-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#suicidal-content .content-wrap::before {
  max-width: 102px;
  background: url("suicidal.4cd55d72.svg") no-repeat top center/91.6666% auto;
}
#poor-judgement:checked ~ #popup-container #poor-judgement-content {
  width: 100%;
  transition: width 0s linear;
}
#poor-judgement:checked ~ #popup-container #poor-judgement-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#poor-judgement-content .content-wrap::before {
  max-width: 72px;
  background: url("poor-judge.12ef461e.svg") no-repeat top center/91.6666% auto;
}
#dropping-out:checked ~ #popup-container #dropping-out-content {
  width: 100%;
  transition: width 0s linear;
}
#dropping-out:checked ~ #popup-container #dropping-out-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#dropping-out-content .content-wrap::before {
  max-width: 72px;
  background: url("bad-grade.9dc7cbb5.svg") no-repeat top center/91.6666% auto;
}
#substance-abuse:checked ~ #popup-container #substance-abuse-content {
  width: 100%;
  transition: width 0s linear;
}
#substance-abuse:checked ~ #popup-container #substance-abuse-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#substance-abuse-content .content-wrap::before {
  max-width: 72px;
  background: url("substance-abuse.f86ea93f.svg") no-repeat top center/91.6666% auto;
}
#video-popup-checkbox:checked ~ #popup-container #video-popup-checkbox-content {
  width: 100%;
  transition: width 0s linear;
}
#video-popup-checkbox:checked ~ #popup-container #video-popup-checkbox-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#video-popup-checkbox-content .content-wrap::before {
  max-width: 0px;
  background: url("iq-bulb.55181b61.svg") no-repeat top center/91.6666% auto;
}
#open-up:checked ~ #popup-container #open-up-content {
  width: 100%;
  transition: width 0s linear;
}
#open-up:checked ~ #popup-container #open-up-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#open-up-content .content-wrap::before {
  max-width: 96px;
  background: url("open-up.dbce5dd9.svg") no-repeat top center/91.6666% auto;
}
#walk-shoes:checked ~ #popup-container #walk-shoes-content {
  width: 100%;
  transition: width 0s linear;
}
#walk-shoes:checked ~ #popup-container #walk-shoes-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#walk-shoes-content .content-wrap::before {
  max-width: 136px;
  background: url("walk-shoes.cd576fe7.svg") no-repeat top center/91.6666% auto;
}
#be-clear:checked ~ #popup-container #be-clear-content {
  width: 100%;
  transition: width 0s linear;
}
#be-clear:checked ~ #popup-container #be-clear-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#be-clear-content .content-wrap::before {
  max-width: 86px;
  background: url("be-clear.c68bc81f.svg") no-repeat top center/91.6666% auto;
}
#stay-calm:checked ~ #popup-container #stay-calm-content {
  width: 100%;
  transition: width 0s linear;
}
#stay-calm:checked ~ #popup-container #stay-calm-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#stay-calm-content .content-wrap::before {
  max-width: 150px;
  background: url("stay-calm.27b34e52.svg") no-repeat top center/91.6666% auto;
}
#use-positivity:checked ~ #popup-container #use-positivity-content {
  width: 100%;
  transition: width 0s linear;
}
#use-positivity:checked ~ #popup-container #use-positivity-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#use-positivity-content .content-wrap::before {
  max-width: 98px;
  background: url("use-positivity.de404439.svg") no-repeat top center/91.6666% auto;
}
#talk-no-lecture:checked ~ #popup-container #talk-no-lecture-content {
  width: 100%;
  transition: width 0s linear;
}
#talk-no-lecture:checked ~ #popup-container #talk-no-lecture-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#talk-no-lecture-content .content-wrap::before {
  max-width: 102px;
  background: url("talk-no-lecture.8ae36bf1.svg") no-repeat top center/91.6666% auto;
}
#mind-language:checked ~ #popup-container #mind-language-content {
  width: 100%;
  transition: width 0s linear;
}
#mind-language:checked ~ #popup-container #mind-language-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#mind-language-content .content-wrap::before {
  max-width: 80px;
  background: url("mind-language.2c19e538.svg") no-repeat top center/91.6666% auto;
}
#active-listening:checked ~ #popup-container #active-listening-content {
  width: 100%;
  transition: width 0s linear;
}
#active-listening:checked ~ #popup-container #active-listening-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#active-listening-content .content-wrap::before {
  max-width: 112px;
  background: url("active-listening.66352d71.svg") no-repeat top center/91.6666% auto;
}
#stick-science:checked ~ #popup-container #stick-science-content {
  width: 100%;
  transition: width 0s linear;
}
#stick-science:checked ~ #popup-container #stick-science-content .content-wrap {
  width: 100%;
  transition: width 0s linear;
}
#stick-science-content .content-wrap::before {
  max-width: 94px;
  background: url("stick-science.36a9449f.svg") no-repeat top center/91.6666% auto;
}
#popup-modal .modal-content .content-wrap::before {
  content: "";
  display: block;
  padding-top: 130px;
}
#popup-modal .modal-content h2 {
  font: 700 min(62px,max(calc( (100vw - 400px) / 6.6666666667 + 32px ),32px))/100% "GroldSlim", Helvetica, Arial, Sans-Serif;
  letter-spacing: -0.02em;
  color: #a4ba25;
  padding-bottom: 28px;
}
#popup-modal .modal-content h2.small {
  font-size: min(40px, 6.667vw);
  color: #000;
  padding-bottom: min(18px, 3vw);
}
#popup-modal .modal-content p {
  color: #1e1517;
  font: 300 min(23px,max(calc( (100vw - 400px) / 33.3333333333 + 17px ),17px))/120% "GroldSlim", Helvetica, Arial, Sans-Serif;
}
#popup-modal .modal-content a {
  display: block;
  padding-top: 56px;
  font: 14px/120% "GroldSlim", Helvetica, Arial, Sans-Serif;
  color: #1e1517;
}
@media (max-width: 600px) {
  #popup-modal .modal-content a {
    font-weight: 300;
  }
}
#video-popup-checkbox:checked ~ #popup-container #popup-modal {
  max-height: calc(67px + min(1000px, 102.4vw - 24px) * 0.5625);
}
#video-popup-checkbox:checked ~ #popup-container #video-popup-checkbox-content {
  width: min(1000px, 102.4vw - 24px);
  height: 0;
  padding-top: 56.25%;
  margin-top: 15px;
  background-color: #000;
  position: relative;
}
#video-popup-checkbox:checked ~ #popup-container #video-popup-checkbox-content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=index.cbabf26a.css.map */
