/*!  scope with: inc/header.php */
$logo-break: $xsmall-width + 75px;
header {
  background-color: $off-white;
  padding: 33px padding-auto(42px, $large-width + 80px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  transition: padding-top 0.3s ease, padding-bottom 0.3s ease;
  @media (max-width: $small-width) {
    padding-left: bend(42px, 18px, $small-width, $xsmall-width);
    padding-right: bend(42px, 18px, $small-width, $xsmall-width);
  }
  @media (max-width: $xsmall-width) {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  &.shrink {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
#logo {
  order: 2;
  flex-shrink: 0;
  h1 {
    opacity: 0.001;
    font-size: 1px;
    position: absolute;
    top: 0;
    left: 0;
  }
  &::before {
    content: "";
    background: url(../img/logo.svg) no-repeat center/contain;
    width: bend(222px, 150px, $logo-break, $xsmall-width);
    padding-top: 35.5%;
    margin-bottom: -25px;
    display: block;
    transition: transform 0.3s ease;
    @media (max-width: $xsmall-width) {
      margin-bottom: -17px;
    }
    header.shrink & {
      transform: scale(0.5945) translateY(-25%);
      @media (max-width: $xsmall-width) {
        transform: scale(0.75) translateY(-15%);
      }
    }
  }
}
#header-nav {
  order: 1;
  flex-shrink: 0;
  width: 34px;
  height: 29px;
  background: url(../img/menu-closed.svg) no-repeat center/contain;
  margin-right: bend(114px, 78px, $large-width, $medium-width);
  cursor: pointer;
  @media (max-width: $small-width) {
    margin-right: bend(78px, 0px, $small-width, $small-width - 78px);
  }
  .menu {
    display: none;
  }
  // So let's just hide the nav cause it doesn;t do anything
  visibility: hidden;
  @media (max-width: $small-width) {
    display: none;
  }
}
#get-help {
  order: 3;
  transition: color 0.3s linear, background-color 0.3s linear;
  margin-right: bend(36px, 0px, $large-width, $medium-width);
  @media (max-width: $xsmall-width) {
    body:not(.alt-layout) & {
      margin-bottom: -36px;
      transition: color 0.3s linear, background-color 0.3s linear,
        margin-bottom 0.3s ease;
    }
    body:not(.alt-layout) header.shrink & {
      margin-bottom: -6px;
    }
  }
  @include hover {
    background-color: $off-black;
    color: $off-white;
  }
}
/*! end scope */
main {
  padding-top: bend(120px, 99px, $logo-break + 12px, $xsmall-width);
  @media (max-width: $xsmall-width) {
    padding-top: 89px;
  }
}
