/*! scope with: inc/popup-carousel.php */
.popup-carousel {
  background-color: $off-white;
  @media (max-width: $small-width) {
    &.popup-carousel {
      padding-left: 0;
      padding-right: 0;
    }
  }
  > .title {
    color: $off-black;
    font: 700 bend(68px, 24px, $container-width, $xsmall-width) / 100% $fnt-dflt;
    letter-spacing: 0.02em;
    padding: bend(66px, 30px, $large-width, $small-width) padding-auto(42px, 720px) 30px;
    text-align: center;
    .alt-layout & {
      font-size: bend(68px, 28px, $container-width, $xsmall-width);
    }
  }

  .carousel {
    max-width: $container-width;
    margin: 0 auto;
    position: relative;
    .prev-btn,
    .next-btn {
      position: absolute;
      width: 40px;
      height: 40px;
      background: url(../img/arrow.svg) no-repeat center/15px auto;
      top: calc(50% - 15px);
      cursor: pointer;
      z-index: 3;
    }
    .prev-btn {
      left: transition(-40px, 5px, $container-width + 100px, $container-width);
    }
    .next-btn {
      transform: scaleX(-1);
      right: transition(-40px, 5px, $container-width + 100px, $container-width);
    }
    @media (max-width: $container-width) {
      .prev-btn,
      .next-btn {
        width: 28px;
        height: 28px;
        .alt-layout & {
          width: 32px;
          height: 32px;
          background-size: auto 63%;
          background-color: #fff;
          border-radius: 100px;
        }
      }
    }
  }
  .carousel-items {
    display: flex;
    overflow: auto;
  }

  .carousel-item {
    padding: 1.8518% 2.2222% 2.9629%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 20%;
    min-width: 163px;
    box-sizing: border-box;
    flex-shrink: 0;
    &:nth-child(odd) {
      background-color: $light-grey;
    }
    &:nth-child(even) {
      background-color: $grey;
    }

    .alt-layout & {
      min-width: 198px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 10px solid #000;
      opacity: 0;
      transform: scale(1.073);
      transition: opacity 0.3s linear, transform 0.4s ease;
    }
    @include hover {
      &::after {
        opacity: 0.15;
        transform: scale(1);
      }
    }

    &[for="mem-loss"]::before {
      background-image: url(../img/memory-loss.svg);
      background-size: 45.1807% auto;
    }
    &[for="use-disorder"]::before {
      background-image: url(../img/disorder-chain.svg);
      background-size: 100% auto;
    }
    &[for="anxiety"]::before {
      background-image: url(../img/anxiety-bolt.svg);
      background-size: 34.2108% auto;
    }
    &[for="distraction"]::before {
      background-image: url(../img/squirrel.svg);
      background-size: 48.1927% auto;
    }
    &[for="iq-decline"]::before {
      background-image: url(../img/iq-bulb.svg);
      background-size: 29.1626% auto;
    }
    &[for="psychosis"]::before {
      background-image: url(../img/spiral.svg);
      background-size: 48.2138% auto;
    }
    &[for="suicidal"]::before {
      background-image: url(../img/suicidal.svg);
      background-size: 53.0351% auto;
    }
    &[for="poor-judgement"]::before {
      background-image: url(../img/poor-judge.svg);
      background-size: 48.2138% auto;
    }
    &[for="dropping-out"]::before {
      background-image: url(../img/bad-grade.svg);
      background-size: 48.2138% auto;
    }
    &[for="substance-abuse"]::before {
      background-image: url(../img/substance-abuse.svg);
      background-size: 49.8209% auto;
    }

    &::before {
      display: block;
      content: "";
      padding-top: 69.94%;
      background-repeat: no-repeat;
      background-position: bottom;
    }
    .title {
      font: bend(14px, 11px, $small-width, $xsmall-width) / 100% $fnt-dflt;
      color: $off-black;
      text-align: center;
      text-transform: uppercase;
      padding-top: 16.8674%;
      margin-bottom: 0;
      .alt-layout & {
        font-size: 14px;
      }
    }
  }
}
/*! end scope */
