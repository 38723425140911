.partners {
  padding: bend(52px, 24px, $small-width, $xsmall-width) 0
    bend(60px, 32px, $small-width, $xsmall-width);
  max-width: 1160px;
  margin: 0 auto;
  h2 {
    font: 700 24px/120% $fnt-dflt;
    color: $off-black;
    text-align: center;
    text-transform: uppercase;
    @media (max-width: $small-width) {
      text-transform: none;
    }
  }
  .partners-wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: bend(38px, 18px, $small-width, $xsmall-width);
    img {
      height: min(150px, 12.931vw);
      width: auto;
      margin-bottom: 0.8%;
    }
  }
}
