// Add blocks specific styling
@import "_block-hero.scss";
@import "_block-plain-text.scss";
@import "_block-popup-list.scss";
@import "_block-talk.scss";
@import "_block-popup-carousel.scss";
@import "_block-suggestions.scss";
@import "_block-pledge.scss";
@import "_block-instagram.scss";
@import "_block-partners.scss";
@import "_block-pledge-2.scss";

.base-section {
  @media (max-width: $small-width) {
    padding-left: bend(42px, 18px, $small-width, $xsmall-width);
    padding-right: bend(42px, 18px, $small-width, $xsmall-width);
  }
}
