/*! scope with: inc/popup-carousel.php */
.popup-list {
  background-color: $pink;
  padding: min(100px, 12.5vw) 12px min(120px, 15vw);

  @media (max-width: 600px) {
    &.base-section {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  > .title {
    color: $off-black;
    letter-spacing: 0.02em;
    margin-bottom: min(60px, 7.5vw);
    text-align: center;
  }

  .list-items {
    max-width: 1400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
  }

  .list-item {
    padding: 1.8518% 2.2222% 0;
    margin: 8px 7px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: calc(20% - 14px);
    min-width: 163px;
    box-sizing: border-box;
    flex-shrink: 0;
    background-color: #fff;

    @media (max-width: 1200px) {
      width: calc(25% - 14px);
    }
    @media (max-width: 1000px) {
      width: calc(33.333% - 10px);
      margin: 5px;
    }
    @media (max-width: 750px) {
      width: calc(50% - 8px);
      margin: 4px;
    }
    @media (max-width: 360px) {
      width: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 10px solid #d8df52;
      opacity: 0;
      transform: scale(1.073);
      transition: opacity 0.3s linear, transform 0.4s ease;
    }
    @include hover {
      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }

    &[for="open-up"]::before {
      background-image: url(../img/open-up.svg);
      background-size: 52.1807% auto;
    }
    &[for="walk-shoes"]::before {
      background-image: url(../img/walk-shoes.svg);
      background-size: 54.1807% auto;
      background-position: center 68%;
    }
    &[for="be-clear"]::before {
      background-image: url(../img/be-clear.svg);
      background-size: 46% auto;
    }
    &[for="stay-calm"]::before {
      background-image: url(../img/stay-calm.svg);
      background-size: 60% auto;
      background-position: center 68%;
    }
    &[for="use-positivity"]::before {
      background-image: url(../img/use-positivity.svg);
      background-size: 48.1927% auto;
    }
    &[for="talk-no-lecture"]::before {
      background-image: url(../img/talk-no-lecture.svg);
      background-size: 50% auto;
    }
    &[for="mind-language"]::before {
      background-image: url(../img/mind-language.svg);
      background-size: 48.2138% auto;
    }
    &[for="active-listening"]::before {
      background-image: url(../img/active-listening.svg);
      background-size: 53.0351% auto;
    }
    &[for="stick-science"]::before {
      background-image: url(../img/stick-science.svg);
      background-size: 48.2138% auto;
    }

    &::before {
      display: block;
      content: "";
      padding-top: 69.94%;
      background-repeat: no-repeat;
      background-position: bottom;
    }
    .title {
      font: clamp(11px, 2vw, 20px) / 100% $fnt-dflt;
      color: $off-black;
      text-align: center;
      padding-top: 16.8674%;
      padding-bottom: 15%;
      margin-bottom: 0;

      @media (max-width: 750px) {
        font-size: clamp(11px, 3.333vw, 20px);
      }
      @media (max-width: 360px) {
        font-size: 20px;
      }
    }
  }
}
/*! end scope */
