/*! scope with: inc/plain-text.php */
.plain-text {
  background-color: $off-black;
  padding: bend(60px, 44px, $large-width, $small-width)
    padding-auto(42px, 864px) 28px;
  color: $off-white;
  font: 300 bend(20px, 14px, $small-width, $xsmall-width) / 130% $fnt-dflt;
  text-align: center;
  position: relative;

  .alt-layout & {
    font-size: bend(20px, 17px, $small-width, $xsmall-width);
    padding-top: 60px;
  }

  &::before {
    content: "";
    height: 6px;
    width: 42px;
    background-color: $yellow;
    position: absolute;
    top: bend(34px, 29px, $large-width, $small-width);
    left: calc(50% - 21px);
  }

  p {
    padding-bottom: 0.7em;
  }
}
/*! end scope */
