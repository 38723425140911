.suggestions {
  padding: bend(140px, 80px, $container-width, $medium-width)
    padding-auto(42px, 1100px) bend(108px, 60px, $container-width, $small-width);
  position: relative;
  background-color: $pink;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  .alt-layout & {
    @media (max-width: $small-width) {
      padding-bottom: 200px;
    }
  }
  .text-wrap {
    width: 60.8974%;
    @media (max-width: $small-width) {
      width: 69%;
      .alt-layout & {
        width: initial;
      }
    }
    h2 {
      font: 700 bend(68px, 24px, $container-width, $xsmall-width) / 120%
        $fnt-dflt;
      color: $off-white;
      letter-spacing: 0.02em;
      padding-bottom: 0.382352em;
      .alt-layout & {
        font-size: bend(68px, 28px, $container-width, $xsmall-width);
      }
    }
    h3,
    p {
      font: bend(18px, 14px, $small-width, $xsmall-width) / 144.444% $fnt-dflt;
      .alt-layout & {
        font-size: bend(18px, 16px, $small-width, $xsmall-width);
      }
    }
    h3 {
      font-weight: 700;
      color: $off-black;
      font-style: italic;
    }
    p {
      color: $off-black;
      padding-bottom: 1em;
    }
    .std-btn {
      background-color: #fff4;
      transition: color 0.3s linear, background-color 0.3s linear;
      display: inline-block;
      margin-top: bend(52px, 8px, $medium-width, $xsmall-width);
      @include hover {
        background-color: $off-black;
        color: $off-white;
      }
    }
  }
  img {
    position: absolute;
    left: max(0px, calc(50vw - 700px));
    top: 50%;
    transform: translate(-50%, -42%);
    height: auto;
    width: auto;
    max-height: 90%;
    @media (max-width: 1080px) {
      width: bend(745px, 230px, 1080px, 300px);
    }
    @media (max-width: $small-width) {
      transform: translate(-61%, -42%);
      .alt-layout & {
        width: 300px;
        height: auto;
        left: 50%;
        top: 90%;
        transform: translate(-50%, -38%);
      }
    }
  }
}
