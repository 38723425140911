.pledge-2 {
  background-color: $pink;
  position: relative;
  padding: 0 42px 24px;
  flex-grow: 1;

  .pledge-cover {
    background-color: $dark-grey;
    padding: bend(70px, 40px, $medium-width, $xsmall-width) 24px;
    position: relative;
    z-index: 2;
    top: bend(-108px, -68px, $small-width, $xsmall-width);
    max-width: 894px;
    margin: 0 auto;
  }
  .text {
    font: bend(20px, 16px, $small-width, $xsmall-width) / 140% $fnt-dflt;
    max-width: 690px;
    margin: 0 auto;
    color: $off-white;
    text-align: center;
  }
  .pledge-count-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font: 700 bend(60px, 24px, $container-width, $xsmall-width) / 100%
        $fnt-dflt;
      color: $yellow;
      letter-spacing: -0.05em;
      padding-top: 0.95em;
      .alt-layout & {
        font-size: bend(60px, 28px, $container-width, $xsmall-width);
      }
    }
    .counter {
      background-color: $yellow;
      color: $off-black;
      font: 300 bend(30px, 24px, $small-width, $xsmall-width) / 100% $fnt-dflt;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      padding: 0.2em 0.15em 0 0.3em;
      .alt-layout & {
        font-size: 30px;
      }
    }
    h3 {
      font: 300 bend(10px, 12px, $small-width, $xsmall-width) / 120% $fnt-dflt;
      padding-top: 0.8em;
      color: $yellow;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      body:not(.alt-layout) & {
        letter-spacing: 0;
      }
    }
  }
  .take-pledge-section {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transition: height 0.3s ease;
    padding-top: 40px;

    .cta-text,
    .new-text {
      max-width: 594px;
      font: 300 bend(22px, 14px, $small-width, $xsmall-width) / 120% $fnt-dflt;
      color: $off-white;
      text-align: center;
      .alt-layout & {
        font-size: 22px;
      }
    }
    .std-btn {
      display: inline-block;
      border-color: $off-white;
      color: $off-white;
      margin-top: 22px;
      transition: background-color 0.2s linear, color 0.2s linear;
      position: relative;
      @media (min-width: $small-width-up) {
        font-size: 18px;
      }
      .alt-layout & {
        font-size: 18px;
      }
      body:not(.alt-layout) & {
        margin-top: bend(58px, 9px, $medium-width, $xsmall-width);
      }

      &::after {
        content: "Thank you";
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity 0.2s linear;
        color: $off-white;
        padding-top: 0.3em;
      }
      &:not(.clicked) {
        @include hover {
          background-color: $off-white;
          color: $off-black;
        }
      }
      &.clicked {
        color: #fff0;
        cursor: default;
        &::after {
          opacity: 1;
        }
      }
    }

    .cta-text,
    .std-btn {
      transition: opacity 0.3s linear, color 0.2s linear,
        background-color 0.2s linear;
    }
    &.taken {
      .cta-text,
      .std-btn {
        display: none;
      }
    }
    &.transitioning {
      .cta-text,
      .std-btn {
        opacity: 0;
      }
    }

    .new-text {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: none;
      opacity: 0;
      transition: opacity 0.4s linear 0.3s;
      align-items: center;
    }
    &.taken .new-text {
      display: flex;
      opacity: 1;
      position: relative;
      height: 100%;
    }
    &.transitioning .new-text {
      display: flex;
      opacity: 1;
      position: absolute;
      height: 100%;
    }
  }

  .brain-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    overflow: hidden;
    max-height: 1152px;
    img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 68%);
      @media (min-height: 1236px) {
        transform: translate(
          -50%,
          min(408px, max(calc((100vh - 1668px) / -1.0588235294 + 0px), 0px))
        );
      }
    }
  }
}
