// Add generic base styling

body {
  // Set style that creates a document flow that always keeps the footer
  // spaced appropriately from the header
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga=0";
  font-feature-settings: "liga=0";
  color: $off-black;
  font: 300 bend(23px, 17px, $small-width, $xsmall-width) / 120% $fnt-dflt;
  > main {
    flex-grow: 1;
  }
  &.pledge-page > main {
    display: flex;
    flex-direction: column;
  }
}

#wpadminbar {
  // Hide the fricken wordpress admin menu so it doesn't screw with styles.
  transform: translateY(calc(-100% + 7px));
  opacity: 0;
  transition: all 0.2s ease;
  z-index: 400;
  &:hover {
    opacity: 1;
    transform: translateY(0);
  }
}

.overflow-hidden {
  overflow: hidden;
}

.title {
  font-weight: bold;
  font-size: clamp(20px, 8vw, 48px);
  line-height: 110%;
  margin-bottom: min(30px, 5vw);
}

.video-link {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: min(50px, 6.25vw) 0 min(50px, 6.25vw) min(87px, 10.875vw);
    border-color: transparent transparent transparent #ffffffe0;
    top: calc(50% - min(50px, 6.25vw));
    left: calc(50% - min(43px, 5.4375vw));
    transition: all 0.25s ease;
  }

  &:hover::after {
    border-color: transparent transparent transparent #ffffffff;
    transform: scale(1.1);
  }
}

.standard-btn,
.standard-button,
.std-btn {
  padding: 0.5276em 1.5em 0.3296em;
  border: 2px solid $off-black;
  border-radius: 100px;
  color: $off-black;
  font: 700 14px/120% $fnt-dflt;
  text-align: center;
  box-sizing: border-box;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  user-select: none;
  cursor: pointer;
  @media (max-width: $xsmall-width) {
    padding: 1.07em 2.1em 0.73em;
    font-size: 13px;
    border-width: 1px;
  }
}

.color-pink {
  color: $pink;
}
