footer {
  background-color: $off-black;
  padding: 32px padding-auto(42px, 954px)
    bend(30px, 12px, $small-width, $xsmall-width);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  @media (max-width: $small-width) {
    flex-direction: column;
    gap: 48px;
  }

  .logo {
    width: 130px;
    height: 46px;
    background: url(../img/logo-white.svg) no-repeat center/contain;
    order: 3;
    @media (max-width: $small-width) {
      width: 213px;
      height: 76px;
      order: 1;
    }
  }

  .links-wrap {
    order: 2;
    display: flex;
  }
  .social-links {
    display: flex;
    gap: 17px;
    > a {
      width: 32px;
      height: 32px;
      background-color: $off-white;
      transition: background-color 0.2s linear;
      display: block;
      position: relative;
      border-radius: 24%;
      @include hover {
        background-color: $yellow;
      }
      &::before {
        content: "";
        position: absolute;
        top: 16%;
        left: 16%;
        right: 16%;
        bottom: 16%;
        background: no-repeat center/contain;
      }
    }
    .facebook::before {
      background-image: url(../img/logo-facebook.svg);
    }
    .instagram::before {
      background-image: url(../img/logo-instagram.svg);
    }
    .twitter::before {
      background-image: url(../img/logo-twitter.svg);
    }
  }
  .alt-links {
    padding-left: 26px;
    > a {
      display: block;
      color: $off-white;
      font: 700 13px/100% $fnt-dflt;
      text-transform: uppercase;
      letter-spacing: 0.06em;
      transition: color 0.2s linear;
      margin-bottom: 0.4em;
      @include hover {
        color: $yellow;
      }
    }
  }

  .copyright {
    order: 4;
    font: 700 11px/100% $fnt-dflt;
    color: $off-white;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-left: bend(152px, 0px, 800px, 648px);
  }
}
