#popups {
  width: 0;
  height: 0;
  input[name="popup"] {
    display: none;
  }
}

#popup-container {
  width: 0;
  height: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.2s linear, height 0s linear 0.2s, width 0s linear 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 201;
  overflow: hidden;
  #popup-close:not(:checked) ~ & {
    width: 100vw;
    height: 100vh;
    opacity: 1;
    transition: opacity 0.2s linear, height 0s linear, width 0s linear;
  }

  .modal-wrap {
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
  }
}
#popup-shadow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0005;
}
#popup-modal {
  position: relative;
  padding: 64px bend(50px, 16px, $small-width, $xsmall-width) 30px;
  width: calc(100% - 24px);
  max-width: 580px;
  border: 1px solid $off-black;
  background-color: $off-white;
  box-sizing: border-box;
  display: flex;
  margin: 12px 0;

  .video-checkbox:checked ~ #popup-container & {
    max-width: 1000px;
    padding: 64px 0 0 0;
  }
  .exit {
    position: absolute;
    top: 25px;
    right: bend(25px, 18px, $small-width, $xsmall-width);
    width: 30px;
    height: 30px;
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 141.42%;
      height: 1px;
      top: 50%;
      left: 50%;
      background-color: $off-black;
    }
    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  .modal-content {
    width: 0;
    overflow: hidden;
    transition: width 0s linear 0.2s;
  }
  .content-wrap {
    width: 10000px;
    transition: width 0s linear 0.2s;
  }
}

/* SPECIFIC MODAL STYLES */
$popup-names: (
  "iq-decline" "iq-bulb" 72px,
  "distraction" "squirrel" 114px,
  "anxiety" "anxiety-bolt" 78px,
  "use-disorder" "disorder-chain" 168px,
  "mem-loss" "memory-loss" 114px,
  "psychosis" "spiral" 72px,
  "suicidal" "suicidal" 102px,
  "poor-judgement" "poor-judge" 72px,
  "dropping-out" "bad-grade" 72px,
  "substance-abuse" "substance-abuse" 72px,
  "video-popup-checkbox" "iq-bulb" 0px,
  "open-up" "open-up" 96px,
  "walk-shoes" "walk-shoes" 136px,
  "be-clear" "be-clear" 86px,
  "stay-calm" "stay-calm" 150px,
  "use-positivity" "use-positivity" 98px,
  "talk-no-lecture" "talk-no-lecture" 102px,
  "mind-language" "mind-language" 80px,
  "active-listening" "active-listening" 112px,
  "stick-science" "stick-science" 94px
);
@each $popup, $img, $width in $popup-names {
  ##{$popup}:checked ~ #popup-container ##{$popup}-content {
    width: 100%;
    transition: width 0s linear;
    .content-wrap {
      width: 100%;
      transition: width 0s linear;
    }
  }
  ##{$popup}-content .content-wrap::before {
    max-width: $width;
    background: url(../img/#{$img}.svg) no-repeat top center/91.6666% auto;
  }
}

#popup-modal .modal-content {
  .content-wrap::before {
    content: "";
    display: block;
    padding-top: 130px;
  }
  h2 {
    font: 700 bend(62px, 32px, $small-width, $xsmall-width) / 100% $fnt-dflt;
    letter-spacing: -0.02em;
    color: $green;
    padding-bottom: 28px;

    &.small {
      font-size: min(40px, 6.667vw);
      color: #000;
      padding-bottom: min(18px, 3vw);
    }
  }
  p {
    color: $off-black;
    font: 300 bend(23px, 17px, $small-width, $xsmall-width) / 120% $fnt-dflt;
  }
  a {
    display: block;
    padding-top: 56px;
    font: 14px/120% $fnt-dflt;
    color: $off-black;
    @media (max-width: $small-width) {
      font-weight: 300;
    }
  }
}

#video-popup-checkbox:checked ~ #popup-container {
  #popup-modal {
    max-height: calc(67px + min(1000px, 102.4vw - 24px) * 0.5625);
  }
  #video-popup-checkbox-content {
    width: min(1000px, calc(102.4vw - 24px));
    height: 0;
    padding-top: 56.25%;
    margin-top: 15px;
    background-color: #000;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
