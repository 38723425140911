@font-face {
  font-family: "GroldSlim";
  font-weight: 300;
  font-style: normal;
  src: url(../fonts/grold-slim-semilight.woff2) format("woff2"),
    url(../fonts/grold-slim-semilight.woff) format("woff");
}
@font-face {
  font-family: "GroldSlim";
  font-weight: 400;
  font-style: normal;
  src: url(../fonts/grold-slim-regular.woff2) format("woff2"),
    url(../fonts/grold-slim-regular.woff) format("woff");
}
@font-face {
  font-family: "GroldSlim";
  font-weight: 700;
  font-style: normal;
  src: url(../fonts/grold-slim-bold.woff2) format("woff2"),
    url(../fonts/grold-slim-bold.woff) format("woff");
}
@font-face {
  font-family: "GroldSlim";
  font-weight: 700;
  font-style: italic;
  src: url(../fonts/grold-slim-bold-italic.woff2) format("woff2"),
    url(../fonts/grold-slim-bold-italic.woff) format("woff");
}
