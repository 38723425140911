/*! scope with: inc/hero.php */
.hero {
  background-color: #000;
  padding: 60px 12px 90px 12px;

  @media (max-width: $large-width) {
    padding-bottom: 25vw;
  }
  .rotating-images {
    height: min(400px, 36.363vw);
    position: relative;
    margin-bottom: min(60px, 6vw);

    img {
      position: absolute;
      top: 0;
      left: 50%;
      width: auto;
      height: 100%;
      transform: translateX(-50%);
      opacity: 0;
      transition: opacity linear 0.4s;

      &.current {
        opacity: 1;
      }
    }
  }
  h2 {
    font: 700 clamp(24px, 8.6vw, 80px) / 100% $fnt-dflt;
    letter-spacing: -0.02em;
    color: $yellow;
    text-align: center;
    position: relative;
    max-width: 820px;
    margin: 0 auto;
    text-transform: uppercase;
  }

  .pledge-page & {
    padding: bend(55px, 35px, $large-width, $small-width) padding-auto(45px, $container-width);
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    background-color: transparent;
    @media (max-width: $large-width) {
      min-height: bend(420px, 192px, $large-width, $xsmall-width);
    }
    min-height: 280px;
    align-items: flex-start;
    padding-top: bend(56px, 36px, $medium-width, $xsmall-width);
    padding-left: 0;
    padding-right: 0;
    @media (max-width: $large-width) {
      min-height: bend(280px, 192px, $medium-width, $xsmall-width);
    }
    padding-bottom: 25vw;
    > img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
    }
    h2 {
      font: 700 bend(80px, 24px, $full-width, $xsmall-width) / 100% $fnt-dflt;
      letter-spacing: -0.02em;
      color: $yellow;
      text-align: center;
      position: relative;
      max-width: $large-width;
      text-transform: none;
      .alt-layout & {
        font-size: bend(80px, 34px, $full-width, $xsmall-width);
      }
    }
  }
}
/*! end scope */
